import IconButton from '@components/IconButton';
import QuestionIcon from '@components/Icons/Question';
import Tooltip from '@components/Tooltip';
import { ITooltipProps } from '@components/Tooltip/Tooltip';
import cx from 'classnames';
import React from 'react';

import styles from './Label.module.scss';

interface IProps extends React.HTMLAttributes<HTMLLabelElement> {
  htmlFor: string;
  className?: string;
  children?: React.ReactNode;
  required?: boolean;
  tooltip?: Omit<ITooltipProps, 'children'>;
}

const Label = ({ children, className, htmlFor, tooltip, required, ...rest }: IProps) => {
  return (
    <div className={styles.labelContainer}>
      <label className={cx(styles.label, className, { [styles.required]: required })} htmlFor={htmlFor} {...rest}>
        {children}
      </label>
      {tooltip ? (
        <Tooltip {...tooltip}>
          {/* TODO Use featherIcon */}
          <IconButton icon={QuestionIcon} onClick={() => null} iconProps={{ size: 16 }} iconSide="left" />
        </Tooltip>
      ) : null}
    </div>
  );
};

export default Label;
