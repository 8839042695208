import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './RadioButtons.module.scss';

const RadioButton = ({ input, label, subLabel, value, id, className, circleStyleButton = true, disabled = false }) => (
  <label
    htmlFor={`${id}-${value}`}
    className={cx(
      styles.label,
      {
        [styles.checked]: input.value === value,
      },
      circleStyleButton ? styles.labelCircle : styles.labelButton,
      className,
    )}
  >
    <input
      id={`${id}-${value}`}
      type="radio"
      data-testid={`radio-button-${id}-${value}`}
      {...input}
      checked={input.value === value}
      value={value}
      disabled={disabled}
    />
    {circleStyleButton ? (
      <>
        <div className={cx(styles.circleWrap, { [styles.disabled]: disabled })} aria-hidden="true" disabled={disabled}>
          <div className={cx(styles.circle, { [styles.disabled]: disabled })} />
        </div>
        <div>
          <div>{label}</div>
          {subLabel && <div className={styles.subLabel}>{subLabel}</div>}
        </div>
      </>
    ) : (
      <button type="button" className={styles.buttonRadio} disabled={disabled}>
        {label}
      </button>
    )}
  </label>
);

RadioButton.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  primaryColor: PropTypes.string,
  className: PropTypes.string,
  circleStyleButton: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default RadioButton;
