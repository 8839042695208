import colors from '@constants/colors';
import PropTypes from 'prop-types';
import React from 'react';

interface IProps {
  fill?: string;
  size?: number;
  [key: string]: string | number;
}

const QuestionIcon = ({ fill, size, ...props }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill={fill} {...props}>
    <g>
      <path d="M 16.175781 3.613281 C 14.886719 2.632812 13.433594 2.296875 11.59375 2.296875 C 10.195312 2.296875 9 2.582031 8.054688 3.21875 C 6.65625 4.171875 6 5.769531 6 8.296875 L 9.601562 8.296875 C 9.601562 7.621094 9.535156 6.894531 9.929688 6.273438 C 10.320312 5.648438 10.871094 5.171875 11.8125 5.171875 C 12.769531 5.171875 13.261719 5.445312 13.726562 6.019531 C 14.117188 6.507812 14.269531 7.085938 14.269531 7.707031 C 14.269531 8.242188 13.996094 8.734375 13.671875 9.179688 C 13.496094 9.441406 13.261719 9.675781 12.964844 9.898438 C 12.964844 9.898438 11.019531 11.058594 10.335938 12.15625 C 9.824219 12.96875 9.640625 13.992188 9.601562 15.214844 C 9.59375 15.304688 9.628906 15.488281 9.9375 15.488281 C 10.242188 15.488281 12.5625 15.488281 12.835938 15.488281 C 13.105469 15.488281 13.164062 15.28125 13.167969 15.195312 C 13.1875 14.75 13.242188 14.066406 13.320312 13.808594 C 13.476562 13.320312 13.777344 12.894531 14.246094 12.53125 L 15.214844 11.859375 C 16.09375 11.175781 16.789062 10.617188 17.101562 10.175781 C 17.628906 9.453125 18 8.5625 18 7.507812 C 18 5.789062 17.367188 4.519531 16.175781 3.613281 Z M 11.34375 17.351562 C 10.128906 17.316406 9.125 18.160156 9.085938 19.476562 C 9.046875 20.796875 10 21.664062 11.21875 21.703125 C 12.484375 21.742188 13.460938 20.925781 13.5 19.609375 C 13.539062 18.289062 12.609375 17.390625 11.34375 17.351562 Z M 11.34375 17.351562 " />
    </g>
  </svg>
);

QuestionIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

QuestionIcon.defaultProps = {
  size: 14,
  fill: colors.light,
};

export default QuestionIcon;
