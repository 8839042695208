import { IWarnOn } from '@types';
import React, { useEffect, useState } from 'react';

import styles from './WarningField.module.scss';

interface IProps extends React.HTMLAttributes<HTMLSpanElement> {
  value: string | string[];
  warnOn: IWarnOn[];
  children?: React.ReactNode;
}

interface IWarning {
  content: string;
  showModalLink?: boolean;
}

/**
 * Contemplates the case where we can have multiple warning based on multiple selected values
 * For example, if x show warning 1, if y show warning 2, if x & y show warning 1 & 2
 */
const WarningField = ({ value, warnOn, ...rest }: IProps) => {
  const [warnings, setWarnings] = useState<(string | JSX.Element)[]>([]);

  useEffect(() => {
    const arrValues = Array.isArray(value) ? value : [value];
    const aux = [];

    warnOn.forEach((w) => {
      if (arrValues.includes(w.value)) {
        aux.push(w.html);
      }
    });

    setWarnings(aux);
  }, [warnOn, value]);

  if (warnings.length === 0) {
    return null;
  }

  return (
    <div className={styles.warningTextWrapper}>
      {warnings.map((w, idx) => {
        if (typeof w === 'string') {
          return <span key={idx} className={styles.warningText} dangerouslySetInnerHTML={{ __html: w }} {...rest} />;
        }

        return (
          <span key={idx} className={styles.warningText} {...rest}>
            {w}
          </span>
        );
      })}
    </div>
  );
};

export default WarningField;
