import cx from 'classnames';
import React from 'react';

import styles from './PlainInput.module.scss';

interface IPlainInputProps extends React.HTMLAttributes<HTMLInputElement> {
  id: string;
  uid?: string;
  className?: string;
  as?: any;
  [key: string]: any;
}

const PlainInput = ({ id, uid, className, as: As = 'input', ...props }: IPlainInputProps) => (
  <As id={id} name={id} uid={!!uid ? uid : id} className={cx(styles.input, className)} {...props} />
);

export default PlainInput;

interface IRefInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  className?: string;
}

const RefInput = ({ className, id, ...rest }: IRefInputProps, forwardedRef) => (
  <input ref={forwardedRef} id={id} className={cx(styles.input, className)} {...rest} />
);

const PlainInputWithRef = React.forwardRef<HTMLInputElement, IRefInputProps>(RefInput);

export { PlainInputWithRef };
