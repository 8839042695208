import QuestionIcon from '@components/Icons/Question';
import Label from '@components/Label';
import Tooltip from '@components/Tooltip';
import colors from '@constants/colors';
import cx from 'classnames';
import { Field, useField } from 'formik';
import React from 'react';

import styles from './Textarea.module.scss';

interface IProps {
  label?: string;
  id: string;
  name?: string;
  className?: string;
  textareaClassName?: string;
  style?: any;
  rows?: number;
  disabled?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  tooltip?: any;
  placeholder?: string;
  onChange?: (e) => void;
  onFocus?: (Element) => void;
  value?: string;
}
const TextArea = ({
  label,
  id,
  textareaClassName,
  className,
  required,
  rows,
  disabled,
  tooltip,
  value = '',
  ...otherProps
}: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = useField<string>(id);

  const { error, touched } = meta;

  return (
    <div className={`${styles.textareaInputContainer}${className ? ` ${className}` : ''}`}>
      {label && <Label required={required} htmlFor={id} className="" dangerouslySetInnerHTML={{ __html: label }} />}
      {tooltip && (
        <Tooltip {...tooltip}>
          <QuestionIcon size={16} fill={colors.primaryPeacock} />
        </Tooltip>
      )}
      <Field
        name={id}
        id={id}
        component="textarea"
        rows={rows || 4}
        className={cx(styles.textareaInput, textareaClassName)}
        disabled={disabled}
        value={field.value || value}
        {...otherProps}
      />
      {touched && error ? <span className={styles.error}>{error}</span> : null}
    </div>
  );
};

export default TextArea;
