import cx from 'classnames';
import React, { useState } from 'react';
import OutsideClick from 'react-outside-click-handler';

import styles from './Tooltip.module.scss';

export type TooltipSizes = 'large' | 'extraLarge';

export interface ITooltipProps {
  header?: string;
  content: string | React.ReactNode;
  children: any;
  direction: string;
  size?: TooltipSizes;
  dark?: boolean;
  toggleOnHover?: boolean;
  hoverShowDelayMs?: number;
}

const Tooltip = (props: ITooltipProps) => {
  const {
    header,
    content,
    children,
    direction,
    size = 'large',
    dark,
    toggleOnHover = true,
    hoverShowDelayMs = 500,
  } = props;
  const [active, setActive] = useState(false);
  const [delayHandler, setDelayHandler] = useState<NodeJS.Timeout>(null);

  const showTooltip = () => {
    setDelayHandler(
      setTimeout(() => {
        setActive(true);
      }, hoverShowDelayMs),
    );
  };

  const hideTooltip = () => {
    clearTimeout(delayHandler);
    setActive(false);
  };

  return (
    <div className={styles.tooltipWrapper}>
      <OutsideClick onOutsideClick={hideTooltip}>
        {toggleOnHover ? (
          <div onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
            {children}
          </div>
        ) : (
          <div onClick={() => setActive((prevActive) => !prevActive)}>{children}</div>
        )}
        {active && (
          <div
            className={cx(styles.tooltipTip, styles[size], dark && styles.tooltipTipDark, {
              [dark ? styles.rightDark : styles.right]: direction === 'right',
              [dark ? styles.leftDark : styles.left]: direction === 'left',
              [dark ? styles.bottomDark : styles.bottom]: direction === 'bottom',
              [dark ? styles.topDark : styles.top]: direction === 'top',
            })}
          >
            {header && <div className={cx(styles.headerTooltip, dark && styles.headerTooltipDark)}>{header}</div>}
            <div className={cx(styles.bodyTooltip, dark && styles.bodyTooltipDark)}>{content}</div>
          </div>
        )}
      </OutsideClick>
    </div>
  );
};

export default Tooltip;
