import IconButton from '@components/IconButton';
import QuestionIcon from '@components/Icons/Question';
import Label from '@components/Label';
import Loading from '@components/Loading';
import PlainInput from '@components/PlainInput';
import Tooltip from '@components/Tooltip';
import cx from 'classnames';
import React from 'react';

import styles from './Input.module.scss';

interface IProps {
  input: any;
  label?: string;
  description?: string;
  className?: string;
  id: string;
  help?: any;
  meta: any;
  type?: string;
  as?: string;
  disabled?: boolean;
  tooltip?: any;
  inputClassName?: string;
  step?: string;
  [key: string]: any;
}

const Input = ({
  input,
  label,
  description,
  className,
  id,
  uid,
  type,
  help,
  meta: { asyncValidating, error, touched },
  as,
  disabled,
  tooltip,
  inputClassName,
  required,
  step,
  ...rest
}: IProps) => {
  let info;
  let infoClassName;

  if (touched && error) {
    infoClassName = styles.error;
    info = error;
  } else if (asyncValidating) {
    infoClassName = styles.loading;
    info = <Loading size="small" />;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { value, ...restInput } = input;
  return (
    <div
      className={cx(styles.input, className, {
        [styles.disabled]: disabled,
      })}
    >
      <div className={cx(styles.inputLabel)}>
        {label ? <Label required={required} htmlFor={id} dangerouslySetInnerHTML={{ __html: label }} /> : null}
        {tooltip ? (
          <Tooltip {...tooltip}>
            <IconButton icon={QuestionIcon} onClick={() => null} iconProps={{ size: 16 }} iconSide="left" />
          </Tooltip>
        ) : null}
      </div>
      {description ? <div className={styles.description}>{description}</div> : null}
      <div>
        <PlainInput
          as={as}
          type={type}
          id={id}
          uid={uid}
          value={input.value === 0 ? 0 : input.value || ''}
          className={inputClassName}
          {...restInput}
          {...rest}
          disabled={disabled}
          step={step}
        />
      </div>
      {info ? <div className={cx(styles.info, infoClassName)}>{info}</div> : null}
      {help ? (
        <div className={cx(styles.info, styles.help)}>{typeof help === 'function' ? help(input.value) : help}</div>
      ) : null}
    </div>
  );
};

export default Input;
