import React from 'react';

import Label from '../../Label';
import DescriptionText from '../DescriptionText';
import WarningField from '../WarningField';
import RadioButton from './RadioButton';
import styles from './RadioButtons.module.scss';
import { IWarnOn } from '@types';

type Option = {
  value: unknown;
  label: string;
  subLabel?: string;
};

type Meta = {
  touched: boolean;
  error?: string;
  warning?: string;
};

type Input = {
  value: string | string[];
};
type Props = {
  input: Input;
  options: Option[];
  meta?: Meta;
  id: string;
  label?: string;
  className?: string;
  radiosClassName?: string;
  circleStyleButton?: boolean;
  warnOn?: IWarnOn[];
  description?: string;
  disabled?: boolean;
  required?: boolean;
  onValueChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
const RadioButtons = ({
  input,
  options = [],
  label,
  warnOn,
  meta: { touched, error, warning } = { touched: false, error: '', warning: '' },
  id,
  className,
  radiosClassName,
  circleStyleButton = true,
  description,
  required,
  disabled,
  onValueChange,
}: Props) => (
  <div className={className}>
    {description ? <DescriptionText dangerouslySetInnerHTML={{ __html: description }} /> : null}
    {label ? <Label required={required} htmlFor={id} dangerouslySetInnerHTML={{ __html: label }} /> : null}
    <div onChange={onValueChange} className={`${styles.radios} ${radiosClassName}`}>
      {options.map((option) => (
        <RadioButton
          id={id}
          key={`radio-key-${option.value}`}
          value={String(option.value)}
          label={option.label}
          subLabel={option.subLabel}
          input={input}
          circleStyleButton={circleStyleButton}
          disabled={disabled}
        />
      ))}
    </div>
    {touched && error ? <span className={styles.error}>{error}</span> : null}
    {touched && warning ? <span className={styles.warning}>{warning}</span> : null}
    {warnOn ? <WarningField value={input.value} warnOn={warnOn} /> : null}
  </div>
);

export default RadioButtons;
