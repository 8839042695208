import useApi from '@api/transportLayer';
import DescriptionText from '@components/Forms/DescriptionText';
import HelpText from '@components/Forms/HelpText';
import WarningField from '@components/Forms/WarningField';
import Label from '@components/Label';
import { IWarnOn } from '@types';
import cx from 'classnames';
import { Field, useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Scroll from 'react-scroll';
import SelectSearch from 'react-select-search/dist/cjs';

import styles from './SearchSelectField.module.scss';
const Element = Scroll.Element;

/**
 * Search Select Field with our own custom render options
 * Formik props. Field includes: name, value, onChange
 */
export const CustomSelectSearch = ({
  field,
  form,
  multiple = false,
  allowSearch = true,
  isCountrySelect = false,
  placeholder,
  onChange,
  ...rest
}: any) => {
  const { value, name, subLabel } = field;
  const { data: flags } = useApi.Flags.getFlags({ canadaLocation: '' }, { enabled: isCountrySelect });

  const handleSelectChange = (newValue) => {
    if (form && newValue !== value) {
      form.setFieldValue(name, newValue?.trim ? newValue.trim() : newValue);
    }
    if (onChange) {
      return onChange(newValue, form);
    }
  };

  return (
    <SelectSearch
      {...rest}
      value={value}
      name={name}
      subLabel={subLabel}
      onChange={handleSelectChange}
      search={allowSearch}
      multiple={multiple}
      printOptions={multiple ? 'on-focus' : 'auto'}
      closeOnSelect={!multiple}
      fuse={false}
      className={(key) => styles[key]}
      placeholder={placeholder || (multiple ? 'Select all that apply' : 'Please select an option')}
      renderOption={(optionProps, optionData, optionSnapshot, className) => {
        if (isCountrySelect && optionData.value === 'CA' && !flags?.canadaLocation) return null;
        return (
          <>
            <button {...optionProps} className={className + ` ${optionData.className || ''}`} type="button">
              <span>
                <div>{optionData.name}</div>
                <div className={styles.sublabel}>{optionData.subLabel}</div>
              </span>
            </button>
          </>
        );
      }}
    />
  );
};

export interface ISearchSelectOption {
  value: string | number;
  name: string;
}

interface IProps {
  id: string;
  options?: ISearchSelectOption[];
  getOptions?: (q: string) => any;
  label?: string;
  help?: string;
  description?: string;
  disabled?: boolean;
  className?: string;
  multiple?: boolean;
  warnOn?: IWarnOn[];
  allowSearch?: boolean;
  showSearchIcon?: boolean;
  autoComplete?: string;
  required?: boolean;
  placeholder?: string;
  isCountrySelect?: boolean;
  onChange?: (id: string, form: any) => any;
}

const SearchSelectField = ({
  id,
  label,
  help,
  description,
  warnOn,
  className,
  required,
  showSearchIcon = false,
  ...rest
}: IProps) => {
  const [field, meta] = useField<string>(id);
  const { error, touched } = meta;

  return (
    <div className={cx(styles.container, className, showSearchIcon ? styles.searchable : styles.default)}>
      {description ? <DescriptionText dangerouslySetInnerHTML={{ __html: description }} /> : null}
      {label ? <Label required={required} htmlFor={id} dangerouslySetInnerHTML={{ __html: label }} /> : null}
      {/* name property doesn't work on this component, so we need Element for the scroll */}
      <Element name={id}></Element>
      <Field component={CustomSelectSearch} name={id} field={field} {...rest} />
      {help ? <HelpText dangerouslySetInnerHTML={{ __html: help }} /> : null}
      {touched && error ? <span className={styles.error}>{error}</span> : null}
      {warnOn ? <WarningField value={field.value} warnOn={warnOn} /> : null}
    </div>
  );
};

SearchSelectField.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ),
  label: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
};

SearchSelectField.defaultProps = {
  disabled: false,
  options: [],
};

export default SearchSelectField;

interface IPlainSearchSelectProps {
  options: ISearchSelectOption[];
  placeholder: string;
  value: string | number;
  onChange: (newValue: string | number) => void;
  allowSearch?: boolean;
  name?: string;
}

export const PlainSearchSelect = ({
  options,
  placeholder,
  value,
  onChange,
  name,
  allowSearch = true,
}: IPlainSearchSelectProps) => {
  return (
    <SelectSearch
      options={options}
      closeOnSelect
      value={value}
      name={name}
      onChange={onChange}
      search={allowSearch}
      multiple={false}
      printOptions="auto"
      fuse={false}
      className={(key) => styles[key]}
      placeholder={placeholder}
    />
  );
};
